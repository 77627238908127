<template>
  <div class="flex min-h-full flex-col pb-12 pt-16">
    <main class="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
      <div class="flex flex-shrink-0 justify-center">
        <a href="/" class="inline-flex">
          <span class="sr-only">MintGarden</span>
          <img class="h-24 w-auto" src="/mint-logo.svg" alt="" />
        </a>
      </div>
      <div class="py-16">
        <div class="text-center">
          <p class="text-sm font-semibold uppercase tracking-wide text-emerald-600">404 error</p>
          <h1 class="mt-2 text-4xl font-extrabold tracking-tight text-neutral-900 dark:text-neutral-50 sm:text-5xl">
            Page not found.
          </h1>
          <p class="mt-2 text-base text-neutral-500 dark:text-neutral-400">
            Sorry, we couldn’t find the page you’re looking for.
          </p>
          <div class="mt-6">
            <router-link to="/" class="text-base font-medium text-emerald-600 hover:text-emerald-500"
              ><span aria-hidden="true">&larr; </span>Go back home</router-link
            >
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
